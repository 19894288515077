.notice-banner {
  width: 100%;
  padding: 2rem 0;
  color: var(--text-light-quaternary);

  .banner-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
  }

  .banner {
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background-color: var(--bg-light-primary);

    .banner-date {
      font-size: 0.9rem;
      margin-bottom: 0.75rem;
      font-weight: 500;
      font-size: var(--text-size-secondary);
      color: rgba(255, 255, 255, 0.75);
    }

    .banner-title {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    .banner-message {
      font-size: 1rem;
      line-height: 1.6;
      margin: 0;
    }

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
    }
  }

  @media (max-width: 768px) {
    padding: 1rem 0;

    .banner {
      padding: 1.5rem;

      .banner-title {
        font-size: 1.25rem;
        color: var(--text-light-quaternary);
      }

      .banner-message {
        font-size: 0.95rem;
        color: var(--text-light-quaternary);
      }
    }
  }
}