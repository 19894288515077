.updates {
  background: var(--brand-purple-light-max);
  display: flex;
  flex-direction: column;
  align-items: center;

  .updates-container {
    display: flex;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    max-width: 1320px;

    .updates-row {
      display: flex;
      flex-direction: row;
      width: 100%;

      .col-md-8 {
        width: 66.66666667%;

        .updates-title {
          padding-left: 5rem;
        }
      }
    }
  }
}

