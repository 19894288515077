body h1 {
  line-height: 1.1;
  font-weight: 600;
  font-size: 3.5rem;

  @media screen and (max-width: 1400px) {
    font-size: 3.5rem;
  }

  @media screen and (max-width: 1199px) {
    font-size: 3.25rem;
  }

  @media screen and (max-width: 991px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 767px) {
    font-size: 2rem;
  }
}

body .row {
  display: flex;
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-top: calc(var(--bs-gutter-y) * -1);
}

.btn {
  border: none;
  border-radius: 45px;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  padding: 14px 24px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.btn-light.active,
.btn-light:hover {
  background-color: var(--brand-blue-dark);
}

.btn-light {
  background-color: var(--brand-blue-light);
  color: var(--text-light-primary);
}
.promo-btn {
  max-width: 120px;
  width: 100%;
}

.promo {
  background: var(--bg-light-primary);
  color: var(--text-light-quaternary);
  overflow: hidden;
  background-size: cover;
  background-position: center;
  position: relative;

  .promo-blur {
    width: 100%;
    backdrop-filter: blur(4px);
  }

  .promo-container {
    display: flex;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    max-width: 1320px;
    padding: 15px;

    .promo-row {
      display: flex;
      flex-direction: row;
      width: 100%;

      .col-md-8 {
        width: 66.66666667%;

        .promo-text-box {
          margin-top: 20vh;
          padding: 2rem;
          border-radius: 15px;
          background-color: var(--bg-light-primary-opaque);

          @media screen and (max-width: 991px) {
            margin-top: 15vh;
          }

          .practice-identity {
            margin-bottom: 1rem;
            margin-top: 0rem;
            
            .practice-name {
              color: white;
              margin-bottom: 0.5rem;
              
              @media screen and (max-width: 767px) {
                font-size: 2rem;
              }
            }
            
            .doctor-name {
              font-size: 2rem;
              color: var(--brand-blue-dark);
              margin-bottom: 3rem;
              margin-top:0;
              
              @media screen and (max-width: 767px) {
                font-size: 1.5rem;
              }
            }
          }
          
          .welcome-message {
            font-size: 2rem;
            color: white;
            margin-bottom: 0.5rem;
            font-weight: 500;
          
            @media screen and (max-width: 767px) {
              font-size: 1.5rem;
            }
          }
  
          .promo-desc {
            color: var(--brand-blue-dark);
            margin-bottom: 2rem;
            max-width: 550px;
          }
        }
        
      }

      .col-md-4 {
        width: 33.33333333%;

        .promo-image {
          //width: 100%; /* Ensure image fits container */
          height: auto;
        }
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;

        .col-md-8,
        .col-md-4 {
          width: 100%;
        }
      }
    }

    /* Adjust container widths at different breakpoints */
    @media screen and (max-width: 1400px) {
      max-width: 1320px;
    }

    @media screen and (max-width: 1199px) {
      max-width: 960px;
    }

    @media screen and (max-width: 991px) {
      max-width: 720px;
    }

    @media screen and (max-width: 767px) {
      max-width: 100%;
    }

    @media screen and (max-width: 575px) {
      padding: 10px;
    }
  }
}
