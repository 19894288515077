.specialties-container {
    display: flex;
    flex-direction: column;

    .specialties-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        .specialties__col {
            flex-grow: 1;
            flex-shrink: 3;
            width: 33.333%;
          
            @media screen and (max-width: 991px) {
                max-width: 50%;
            }
          
            @media screen and (max-width: 767px) {
                flex-basis: 100%;
                max-width: 100%;
            }
          }
    }

    .specialties-list {
        display: flex;
        flex-wrap: wrap;
        gap: 5rem;

        .specialty {
            background: var(--bg-light-primary);
            border-radius: 10px;
            text-align: center;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            color: white;
            
            .specialty__content {
                display: flex;
                flex-direction: column;
                align-items: center;
            
            }
            
            .specialty__name {
                font-size: 1.25rem;
                font-weight: bold;
                margin-bottom: 1rem;
            }
            
            .specialty__icon {
                padding: 0 !important;
                img {
                    max-width: 100%;
                }
            }
            
            .specialty__description {
                font-size: 1rem;
                line-height: 1.6;
                margin-top: 1rem;
                padding: .5rem
            }
        }
    }
}
  
  
  
  