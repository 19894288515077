:root {
  --brand-blue-normal: #b8e1ee;
  --brand-blue-dark: #b0ddeb;
  --brand-blue-light: #c1e9f5;
  --brand-blue-light-max: #f3fbfd;
  --brand-purple-normal: #a68ff1;
  --brand-purple-dark: #9e85f0;
  --brand-purple-light: #af99f5;
  --brand-purple-light-max: #f1f0f8;
  --text-light-primary: #173a43;
  --text-light-secondary: #485f65;
  --text-light-tertiary: #798588;
  --text-light-quaternary: #fff;
  --text-white: #fff;
  --text-dark-secondary: #798588;
  --text-dark-tertiary: #485f65;
  --text-dark-quaternary: #173a43;
  --bg-light-primary: rgb(0, 86, 139);
  --bg-light-primary-opaque: rgba(0, 89, 129, .9);
  --bg-light-secondary: #1186cb;
  --bg-light-tertiary: #38b6ff;
  --bg-light-quaternary: #e3e3e3;
  --bg-light-quinary: #ebebeb;
  --bg-light-senary: #eeeeef;
  --bg-light-septenary: #f8f9fb;
  --bg-light-octonary: #fff;
  --bg-light-dark: #0e353f;
  --bg-dark-octonary: #090b0c;
  --text-size-main: 18px;
  --text-size-secondary: 16px;
  --text-size-tertiary: 14px;
}
