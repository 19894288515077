.contact {
  box-sizing: border-box;
  
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    .form-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      box-sizing: border-box;

      .title {
      }

      .description {
      }

      .form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: white;
        border-radius: 20px;
        padding: 20px;
        margin-top: 20px;
        gap: 1rem;
        box-sizing: border-box;

        @media screen and (max-width: 480px) {
          padding: 15px;
        }

        .row {
          width: 100%;
          gap: 10px;
          box-sizing: border-box;

          .input-container {
            display: flex;
            flex-grow: 1;
            align-items: center;
            width: 100%;
            box-sizing: border-box;

            .form-input {
              background-color: #fff;
              border: 1px solid rgba(0, 0, 0, 0.16);
              border-radius: 24px;
              padding: 0.875rem 1.5rem;
              width: 100%;
              box-sizing: border-box;
            }

            textarea {
              padding-bottom: 5rem; // Make room for the counter
              resize: none; // Prevent resizing
              min-height: 150px; // Set a fixed height
            }

            .privacy-link {
              background: none;
              border: none;
              color: blue;
              text-decoration: underline;
              cursor: pointer;
              padding: 0;
              font-size: 1rem;
              margin-left: 0.25rem;
            }
            
            .privacy-link:hover {
              color: darkblue;
            }            
          }
        }

        .message-container {
          position: relative;
          
          .textarea-wrapper {
            position: relative;
            width: 100%;
            
            textarea {
              width: 100%;
              box-sizing: border-box;
              padding: 1rem;
              padding-bottom: 3rem; 
              resize: none;
              min-height: 150px;
              line-height: 1.5; 
              display: block; 
            }
            
            .character-count {
              font-size: 0.875rem;
              color: #536471;
              display: flex;
              justify-content: flex-end;
              gap: 0.25rem;
              align-items: center;
              pointer-events: none;
              background-color: rgba(255, 255, 255, 0.9); 
              padding: 0.25rem 0.5rem; 
              border-radius: 4px; 
              
              .near-limit {
                color: #f4212e;
              }
              
              .separator {
                color: #536471;
              }
            }
          }
        }

        .form-submit {
          border: none;
          border-radius: 45px;
          cursor: not-allowed;
          font-weight: 500;
          padding: 14px 24px;
          -webkit-transition: all .4s;
          transition: all .4s;

          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }

          .loading-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
          }

          .spinner {
            animation: spin 1s linear infinite;
          }
        }

        .form-submit-enabled {
          background-color: var(--brand-blue-light);
          cursor: pointer !important;
        }

        .grecaptcha-badge { 
          visibility: hidden; 
        }
      }
    }

    // Screen reader only class
    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }

    // Error message styling
    .error-message {
      color: #dc3545;
      font-size: 0.875rem;
      margin-top: 0.25rem;
    }

    // Focus styles for better accessibility
    input:focus,
    textarea:focus,
    button:focus {
      outline: 2px solid #4A90E2;
      outline-offset: 2px;
    }

    // High contrast focus styles for keyboard navigation
    input:focus-visible,
    textarea:focus-visible,
    button:focus-visible {
      outline: 3px solid #005FCC;
      outline-offset: 2px;
    }

    // Disabled state styling
    button[disabled] {
      opacity: 0.65;
      cursor: not-allowed;
    }

    // Error state styling for form inputs
    input[aria-invalid="true"],
    textarea[aria-invalid="true"] {
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
