@import "src/variables";

.header {
  position: fixed;
  padding: 20px 0 0 0;
  top: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 12;

  * {
    max-width: 100%;
    box-sizing: border-box;
  }

  .header-container {
    @media screen and (max-width: 991px) {
      display: none;
    }

    margin-right: auto;
    margin-left: auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    max-width: 1400px;

    .header-inner {
      display: flex;
      align-items: center;
      background-color: var(--bg-light-secondary);
      color: var(--text-light-primary);
      border-radius: 16px;
      padding: 1rem 1.5rem;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 24px rgba(0, 0, 0, 0.12);
      }

      .logo {
        flex-shrink: 0;
        margin-right: 2rem;
        display: flex;

        button {
          border: none;
          background: none;
          display: flex;
          padding: 0;
          cursor: pointer;
          transition: transform 0.2s ease;
          
          img {
            width: 40px;
            height: 40px;
            border-radius: 12px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            transition: box-shadow 0.2s ease;
          }

          &:hover img {
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          }
        }
      }

      .header-navigation {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        .navigation-main {
          margin-right: 2rem;
          
          .primary-menu-container {
            .primary-menu {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              list-style-type: none;
              margin: 0;
              padding: 0;

              .primary-menu-item {
                padding: 0;
                display: list-item;

                button {
                  color: var(--text-light-quaternary);
                  font-size: var(--text-size-secondary);
                  border: none;
                  background: none;
                  padding: 0.75rem 1.25rem;
                  border-radius: 12px;
                  transition: all 0.2s ease;
                  position: relative;
                  font-weight: 500;

                  &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 0;
                    height: 2px;
                    background-color: var(--brand-blue-light);
                    transition: all 0.2s ease;
                    transform: translateX(-50%);
                  }

                  &:hover {
                    background-color: var(--brand-blue-light);
                    color: var(--text-light-primary);
                    cursor: pointer;

                    &::after {
                      width: 0;
                    }
                  }
                }
              }
            }
          }
        }

        .navigation-secondary {
          .navigation-button {
            margin-right: .5rem;
            background-color: var(--brand-blue-light);
            border-radius: 45px;
            font-size: var(--text-size-tertiary);
            padding: .05rem .05rem;

            &:hover {
              background-color: var(--brand-blue-dark);
            }

            button {
              color: var(--text-light-primary);
              text-decoration: none;
              background: none;
              border: none;
            }
          }
        }
      }
    }
  }

  .header-mobile-button {
    max-width: 100%;
    display: none;

    @media screen and (max-width: 991px) {
      display: flex;
      z-index: 13;
      max-width: 100%;
      flex-direction: row-reverse;
      padding-right: 1rem;

      .header__navigation-btn-menu {
        width: 50px;
        height: 50px;
        padding: 10px;
        background: var(--brand-blue-light);
        border-radius: 50%;
        outline: none;
        box-sizing: border-box;
        margin: 0;
      }
    }
  }

  .mobile-nav-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Fallback */
    height: -webkit-fill-available;
    height: stretch;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
    justify-content: flex-end;
    
    &.active {
      display: flex;
    }
    
    .mobile-nav-content {
      width: 80%;
      max-width: 400px;
      height: 100vh; /* Fallback */
      height: -webkit-fill-available;
      height: stretch;
      background-color: var(--bg-light-secondary);
      display: flex;
      flex-direction: column;
      box-shadow: -4px 0 12px rgba(0, 0, 0, 0.1);
      transform: translateX(100%);
      transition: transform 0.3s ease;
      overflow-y: auto; /* Enable scrolling if content is too tall */
      -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */

      &.open {
        transform: translateX(0);
      }

      .mobile-nav-header {
        position: sticky;
        top: 0;
        background-color: var(--bg-light-secondary);
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .logo button {
          border: none;
          background: none;
          display: flex;
          padding: 0;
          cursor: pointer;
          
          img {
            width: 40px;
          }
        }

        .mobile-nav-close {
          background: none;
          border: none;
          font-size: 1.5rem;
          cursor: pointer;
          color: var(--text-light-quaternary);
        }
      }

      .mobile-navigation {
        flex-grow: 1;
        overflow-y: auto;
        padding-bottom: env(safe-area-inset-bottom); /* Account for iPhone home indicator */

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            button {
              width: 100%;
              text-align: left;
              padding: 1rem;
              background: none;
              border: none;
              color: var(--text-light-quaternary);
              font-size: var(--text-size-secondary);
              cursor: pointer;
              transition: background-color 0.2s ease;

              &:hover {
                background-color: rgba(0, 0, 0, 0.05);
              }
            }
          }
        }
      }

      .mobile-language-selector {
        padding: 1rem;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
}
