@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background: var(--brand-purple-light-max);
  overflow-x: hidden;

  .global-message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 1rem;
    max-width: 100%;
    width: 100%;
    text-align: center;
    //color: white;
  
    &.success {
      background-color: rgba(0, 128, 0, 0.1); /* Light green */
      border: 2px solid green;
    }
  
    &.error {
      background-color: rgba(255, 0, 0, 0.1); /* Light red */
      border: 2px solid red;
    }
  
    .icon {
      margin-right: 10px;
      font-size: 1.5rem;
    }
  
    .message {
      flex: 1;
    }
  }

  .container {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    padding: 0rem 5rem;

    @media screen and (max-width: 972px) {
      padding: 0 2rem;
    }

    h1 {
      margin-top: 0;
    }
  }

  section {
    background: var(--brand-purple-light-max);
    &:not(:first-of-type) {
      padding-top: 7rem;

     @media screen and (max-width: 972px) {
        padding-top: 5rem;
     }

    }
  }
}