.language-switcher {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  button {
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.2s ease;
    position: relative;

    &:focus-visible {
      outline: 2px solid var(--brand-blue-light);
      outline-offset: 2px;
    }

    &.active::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 2px;
      background-color: var(--brand-blue-light);
      border-radius: 2px;
    }

    img {
      border-radius: 4px;
      display: block;
    }
  }

  .divider {
    color: var(--text-light-quaternary);
    opacity: 0.5;
    user-select: none;
  }

  // Screen reader only class
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}