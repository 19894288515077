.footer {
    background-color: var(--bg-light-primary);
    padding: 4rem 0;
    text-align: center;
    font-size: 16px;
  
    .footer-container {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 2rem;
      margin-bottom: 2rem;
      padding: 0 1rem; /* Added padding to avoid content touching the sides of the screen */
      width: 100%;
      box-sizing: border-box; /* Prevents overflow */
    }
  
    .footer-logo {
      flex: 1;
      h3 {
        font-size: 24px;
        font-weight: bold;
      }
    }
  
    .footer-links {
      flex: 1;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        gap: 1.5rem;

        .footer-link {
          color: white;
          text-decoration: none;
          font-weight: bold;
  
          &:hover {
            color: var(--brand-purple-dark);
          }
        }
        button {
          border: none;
          background: none;
          font-size: 1rem;
          padding: 0;
        }
       
      }
    }
  
    .footer-social {
      flex: 1;
      display: flex;
      justify-content: center;
      gap: 1.5rem;
  
      .social-link {
        color: white;
        font-size: 20px;
        transition: color 0.3s;
  
        &:hover {
          color: var(--brand-purple-dark);
        }
      }
    }
  
    .footer-bottom {
      color: white;
      font-size: 14px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      padding-top: 1rem;
      margin-top: 2rem;
      width: 100%;
      box-sizing: border-box; /* Prevents overflow */
      
      p {
        margin: 0;
      }
  
      .recaptcha-disclaimer {
        font-size: 14px;
        margin-top: 1rem;
  
        a {
          color: white;
          text-decoration: underline;
          font-weight: 500;
  
          &:hover {
            color: var(--brand-purple-dark);
          }
        }
  
        span {
          font-size: 14px;
        }
      }
    }
  
    @media (max-width: 768px) {
      padding: 3rem 0;
  
      .footer-container {
        flex-direction: column;
        align-items: center;
        gap: 1rem; /* Reduce gap on smaller screens */
        padding: 0 1rem; /* Added padding for mobile to prevent overflow */
      }
  
      .footer-logo {
        flex: none;
        text-align: center;
        margin-bottom: 1rem;
      }
  
      .footer-links {
        flex: none;
        ul {
          flex-direction: column;
          gap: 1rem;
          justify-content: center;
        }
      }
  
      .footer-social {
        flex: none;
        justify-content: center;
        gap: 1rem;
      }
  
      .footer-bottom {
        font-size: 12px;
        padding-top: 0.75rem;
      }
    }
  
    @media (max-width: 480px) {
      padding: 2.5rem 0;
  
      .footer-container {
        padding: 0 0.5rem; /* Reduce padding for very small screens */
      }
  
      .footer-logo {
        font-size: 20px; /* Smaller logo text on small screens */
        margin-bottom: 1rem;
      }
  
      .footer-links {
        ul {
          gap: 0.5rem; /* Smaller gap for smaller screens */
        }
      }
  
      .footer-social {
        gap: 1rem;
      }
  
      .footer-bottom {
        font-size: 11px;
      }
    }
  }
  